// Libs
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// Images
import arrowRightIcon from 'images/icons/arrow-right.svg';

// Components
import { device } from '../device';

// Styles
const Container = styled.div`
  padding: 2rem;
  margin-bottom: ${props => props.intern && '4.375rem'};
  width: ${props => (props.intern ? '100%' : '43%')};
  border-radius: 8px;
  color: #231f20;
  background-color: ${props => props.intern && '#f4f5f7'};

  @media ${device.laptop} {
    padding: 1.875rem 2.1rem;
    margin-left: -2rem;
    width: calc(100% + 3.5rem);
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    padding: 1.875rem 3rem 3rem 3rem;
    margin-left: -3rem;
    width: calc(100% + 6rem);
  }

  @media ${device.mobile} {
    padding: 1.875rem 1.5rem;
    margin-left: -1.5rem;
    width: calc(100% + 2.9rem);
  }

  a {
    margin-bottom: 0.625rem;
    height: 3.5625rem;
    width: ${props => (props.intern ? '100%' : '84%')};
    display: flex;
    font-size: 1rem;
    border-radius: 6.25rem;
    cursor: pointer;
    border: 0.0625rem solid #c6c8cc;
    background: #fff;

    @media ${device.laptop} {
      width: 100%;
      font-size: 0.875rem;
    }

    p {
      padding: 1.2rem 1.25rem 1.35rem 1.25rem;
      width: 100%;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #231f20;

      @media ${device.laptop} {
        padding: 1.1875rem 1.125rem;
      }

      @media ${device.mobile} {
        padding: 1.1875rem 1.5rem;
      }
    }
  }
`;

const InternTitle = styled.h5`
  font-size: 1.25rem;
  margin: 0;
  padding-bottom: 1.875rem;
  color: #231f20;
  font-weight: 400;
`;

const Menu = ({ intern, pathname }) => {
  const menuData = [
    {
      name: 'Quero tirar dúvidas',
      link: 'central-de-atendimento',
    },
    {
      name: 'Quero fazer um agendamento',
      link: 'agendamento-de-consultas-e-exames',
    },
    {
      name: 'Quero um orçamento particular',
      link: 'central-de-orcamentos',
    },
    {
      name: 'Quero ajuda com autorizações',
      link: 'central-de-guias',
    },
    {
      name: 'Quero ajuda com o Portal do Paciente',
      link: 'portal-do-paciente',
    },
    {
      name: 'Quero fazer um elogio ou reclamação',
      link: 'elogio-ou-reclamacao',
    },
    {
      name: 'International Patient Assistance',
      link: '/international-patient',
    },
  ];

  const handleInternData = () => {
    const path = pathname.split('/')[2];

    const filtered = menuData.filter(item => item.link !== path);

    return filtered.map(item => (
      <Link key={item.link} to={`/fale-conosco/${item.link}`}>
        <p>
          {item.name}
          <img src={arrowRightIcon} alt={item.name} />
        </p>
      </Link>
    ));
  };

  return (
    <Container intern={intern}>
      {intern ? (
        <>
          <InternTitle>Gostaria de falar sobre outro assunto?</InternTitle>
          {handleInternData()}
        </>
      ) : (
        menuData.map(item => (
          <Link key={item.link} to={item.link}>
            <p>
              {item.name}
              <img src={arrowRightIcon} alt={item.name} />
            </p>
          </Link>
        ))
      )}
    </Container>
  );
};

export default Menu;
